
import React from "react";
class Loading extends React.Component {
    render() {

        return (
            <div className="loading">
               
            </div>)
    }
}
export default Loading;
